@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Ubuntu", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background-gpsi {
  background: rgb(254, 140, 74);
  background: linear-gradient(
    90deg,
    rgba(254, 140, 74, 1) 0%,
    rgba(255, 200, 85, 1) 100%
  );
}

.table-header {
  font-size: 0.9em;
  background: rgb(189, 189, 189);
  background: linear-gradient(
    0deg,
    rgba(189, 189, 189, 1) 0%,
    rgba(244, 244, 244, 1) 100%
  );
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.table tbody {
  font-size: 0.9em;
}

.table tbody tr:hover {
  background-color: rgba(134, 187, 248, 0.5) !important;
}

.main-content {
  margin-top: 40px;
}

.has-background-psisim {
  background: rgb(5, 150, 105);
  background: linear-gradient(
    115deg,
    rgba(5, 150, 105, 1) 0%,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
}

.imageLogin {
  width: 100%;
}

.textPsi {
  font-family: "Ubuntu", sans-serif;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
}

.bg-main {
  background-color: #022c22;
}

.navbar-item:hover,
.navbar-link:hover {
  border-radius: 15px;
  padding: 10px 15px;
}

.navbar .is-active {
  border-radius: 15px;
  background-color: hsl(219, 70%, 96%) !important;
}

.is-linethrough {
  text-decoration: line-through;
}

.text-bold {
  font-weight: bold;
}

.boxCard {
  margin: 0;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.labelPage {
  background: rgb(32, 171, 135);
  background: linear-gradient(
    90deg,
    rgba(32, 171, 135, 1) 0%,
    rgba(115, 211, 151, 1) 100%
  );
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 8px;
  height: 90px;
  position: relative;
}

.labelPage2 {
  background: rgb(2, 96, 162);
  background: linear-gradient(
    0deg,
    rgba(2, 96, 162, 1) 0%,
    rgba(2, 130, 191, 1) 100%
  );
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 8px;
  height: 90px;
  position: relative;
}

.iconLabel {
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #57c29f;
}

.iconLabel2 {
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  left: 60px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card-radius {
  border-radius: 5px;
}

.menu-shortcut {
  border-radius: 20px;
  background: rgb(2, 96, 162);
  background: linear-gradient(
    0deg,
    rgba(2, 96, 162, 1) 0%,
    rgba(2, 130, 191, 1) 100%
  );
}

.menu-shortcut:hover {
  border: 2px solid #fe8c4a;
}

.label {
  color: grey;
}

.input {
  font-family: "Ubuntu", sans-serif;
}

.labelTitle {
  font-family: "Ubuntu", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.img .fotoAbsen {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.footer {
  text-align: center;
  padding-top: 2em;
  background-color: #ffffff;
  color: #bdbdbd;
  font-family: "Ubuntu", sans-serif;
  font-size: 0.8em;
}
